import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebStorageModule } from 'ngx-store';
import { TextMaskModule } from 'angular2-text-mask';
import {AngularSvgIconModule, SvgIconRegistryService} from 'angular-svg-icon';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './login/login.component';
import { SecurityInterceptor } from './security/security-interceptor';
import { ErrorsInterceptor } from './errors/errors-interceptor';
import { AppCommonsModule } from './app-commons/app-commons.module';
import { CaptchaDirective } from './captcha/captcha.directive';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { RegistrationComponent } from './registration/registration.component';
import { HeaderComponent } from './header/header.component';
import { NotificationCountComponent } from './notification/count/notification-count.component';
import { ProfileHeaderComponent } from './profile-header/profile-header.component';
import { EsiaCallbackComponent } from './esia/esia-callback/esia-callback.component';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { ApiModule, BASE_PATH } from '@rsmu/portal-api';
import { environment } from '../environments/environment';
import { GridModule } from './grid-module/grid.module';
import { TabsModule } from './tabs/tabs.module';
import { HttpLoaderFactory, HttpLoaderFactoryDeps, } from './i18n/translate-http-loader-with-speech-modules';
import { SubmenuComponent } from './submenu/submenu.component';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import { EsiaStartComponent } from './esia/esia-start/esia-start.component';
import { ElementCreatedDirective } from './utils/element-created.directive';
import { ToastrModule } from 'ngx-toastr';
import { DatepickersModule } from './app-commons/datepickers/datepickers.module';
import { DialogConfirmComponent } from './app-commons/dialog/confirm/confirm.component';
import { QuestionaryModule } from './app-commons/questionary/questionary.module';
import { UserService } from './user/user.service';
import { IdpService } from './idp/idp.service';
import { SentryErrorHandler } from './sentry/sentryErrorHandler';
import { EsiaErrorComponent } from './esia-error/esia-error.component';
import { RegistrationResultComponent } from './registration-result/registration-result.component';
import { PasswordRecoveryResultComponent } from './password-recovery-result/password-recovery-result.component';
import {ConfigFactory, ConfigLoader, IMG_LINKS_CONFIG} from './utils/config-loader';
import { IfmoCallbackComponent } from './ifmo/ifmo-callback/ifmo-callback.component';
import {SimpleResponseInterceptor} from './simple-response/simple-response-interceptor';
import { RecoveryComponent } from './recovery/recovery.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {PaginatorIntl} from './app-commons/material/paginator/paginator-intl';
import { EsiaLoadingDataDialogComponent } from './esia/esia-loading-data-dialog/esia-loading-data-dialog.component';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';
import {RootStoreModule} from './root-store';
import {ApiModule as OrgApiModule, Configuration, ConfigurationParameters} from '@rsmu/es-rest-client';
import { HaveToUpdateDialogComponent } from './mobile/have-to-update-dialog/have-to-update-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import { YandexCaptchaDirective } from './captcha/yandex-captcha.directive';
export function orgApiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.orgApiUrl
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    CaptchaDirective,
    DialogConfirmComponent,
    EsiaCallbackComponent,
    HeaderComponent,
    LoginComponent,
    NotificationCountComponent,
    EsiaStartComponent,
    EsiaErrorComponent,
    ElementCreatedDirective,
    PasswordRecoveryComponent,
    ProfileHeaderComponent,
    RegistrationComponent,
    RootComponent,
    SubmenuComponent,
    RegistrationResultComponent,
    PasswordRecoveryResultComponent,
    IfmoCallbackComponent,
    RecoveryComponent,
    EsiaLoadingDataDialogComponent,
    HaveToUpdateDialogComponent,
    YandexCaptchaDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule,
    OrgApiModule.forRoot(orgApiConfigFactory),
    FormsModule,
    ReactiveFormsModule,
    AppCommonsModule,
    DatepickersModule,
    TextMaskModule,
    QuestionaryModule,
    WebStorageModule,
    AngularSvgIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: HttpLoaderFactoryDeps()
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    GridModule,
    TabsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RootStoreModule,
    MatCardModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSliderModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SimpleResponseInterceptor, multi: true},
    ConfigLoader,
    {provide: 'IMG_LINKS_CONFIG_PATH', useValue: environment.imgLinksConfPath},
    {provide: IMG_LINKS_CONFIG, useFactory: ConfigFactory, deps: [ConfigLoader, 'IMG_LINKS_CONFIG_PATH']},
    {
      provide: MESSAGE_FORMAT_CONFIG, useValue: {
        formatters: {
          'russianFormatter': {
            locales: 'ru'
          },
          spaceIfEmpty
        }
      }
    },
    {provide: BASE_PATH, useValue: environment.baseRestApiUrl},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    DatePipe,
    // ngx-charts changing route behavior
    // https://github.com/swimlane/ngx-charts/issues/601
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MatPaginatorIntl, useClass: PaginatorIntl}
  ],
    exports: [
        SubmenuComponent,
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // noinspection JSUnusedLocalSymbols  | UserService: instantiate service here, because it's only handles events from another service
  // IdpService: store ticket here, before router is initialized
  constructor(userService: UserService, idpService: IdpService) {
    idpService.storeIdpTicket(window.location.hash);
  }
}

export function spaceIfEmpty(val: any) {
  return val == null || val.toString().length === 0 ? '' : ' ';
}
